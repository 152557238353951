<template>
  <div class="map">
    <div class="options cleanslate">
      <transition name="wrap">
        <div class="wrap" v-if="loaded">
          <vSearch @search="search" />
          <vFilter
            v-bind="{ items }"
            :type.sync="type"
            @resetPopup="popup = null"
          />
        </div>
      </transition>
    </div>

    <div class="gmap">
      <GmapMap
        ref="map"
        :center="{ lat: settings.lat, lng: settings.lng }"
        style="width: 100%; height: 600px"
        map-type-id="roadmap"
        :zoom="settings.zoom"
        v-bind="{ options }"
      >
        <GmapCluster :zoomOnClick="true">
          <vMarker
            v-for="item in itemsFilter"
            :key="item.id"
            :popup.sync="popup"
            v-bind="item"
          />
        </GmapCluster>
      </GmapMap>
    </div>
  </div>
</template>

<script>
import GmapCluster from "vue2-google-maps/dist/components/cluster";
import vMarker from "./marker.vue";
import vSearch from "./search.vue";
import vFilter from "./filter.vue";
import config from "../config";

export default {
  components: { GmapCluster, vMarker, vSearch, vFilter },
  props: {
    items: { type: Array, required: false },
  },
  data() {
    return {
      popup: null,
      type: "all",

      options: {
        streetViewControl: false,
        styles: [
          {
            featureType: "administrative",
            elementType: "labels.text.fill",
            stylers: [{ color: "#444444" }],
          },
          {
            featureType: "landscape",
            elementType: "all",
            stylers: [{ color: "#f2f2f2" }],
          },
          {
            featureType: "poi",
            elementType: "all",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "road",
            elementType: "all",
            stylers: [{ saturation: -100 }, { lightness: 45 }],
          },
          {
            featureType: "road.highway",
            elementType: "all",
            stylers: [{ visibility: "simplified" }],
          },
          {
            featureType: "road.arterial",
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "transit",
            elementType: "all",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "water",
            elementType: "all",
            stylers: [{ color: "#4f595d" }, { visibility: "on" }],
          },
        ],
      },
    };
  },
  computed: {
    settings: () => config.settings,
    loaded: (t) => t.items.length > 0,

    itemsFilter() {
      if (this.type === "showrooms") {
        return this.items.filter((item) => {
          return [1, 235].indexOf(item.id) !== -1;
        });
      }
      return this.type === "all"
        ? this.items
        : this.items.filter((item) => {
            return item.types.some((x) => x.id === this.type);
          });
    },
  },
  methods: {
    search(place) {
      if (!place || !place.geometry) return;
      this.$refs.map.$mapObject.fitBounds(place.geometry.viewport);
    },
  },
};
</script>

<style lang="scss">
@import "@/style/cleanslate.scss"; // reset all css
</style>

<style lang="scss" scoped>
.map {
  position: relative;
  display: flex;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }

  .options {
    border: 1px solid #d8dfe7;
    border-radius: 2px;
    margin-right: 0.5em;
    width: 250px;
    flex-shrink: 0;
    overflow: hidden;

    @media screen and (max-width: 1024px) {
      width: 100%;
      margin-bottom: 0.5em;
    }

    .wrap {
      $t: 0.2s;

      &-enter-active,
      &-leave-active {
        transition: cubic(opacity, $t), cubic(transform, $t);
      }
      &-enter,
      &-leave-to {
        opacity: 0;
        transform: translateX(10px) scale(0.98);
      }
    }
  }

  .gmap {
    width: 100%;
  }
}
</style>
