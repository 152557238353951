<template class="cl-marker">
  <GmapMarker v-bind="{ position, icon, clickable: true }" @click="toggle">
    <GmapInfoWindow v-if="popup === id">
      <div class="popup">
        <div class="name">{{ name }}</div>

        <div class="info">
          <div class="location">
            <div class="location1">{{ location1 }}</div>
            <div class="location2">{{ location2 }}</div>
          </div>
          <div class="phone" v-if="phone">
            Phone: <a :href="phoneLink">{{ phone }}</a>
          </div>
          <!-- <div class="email" v-if="email">Email: <a :href="emailLink">{{email}}</a></div> -->
          <div class="website" v-if="website">
            Website: <a :href="websiteLink" target="_blank">{{ website }}</a>
          </div>
        </div>
      </div>
    </GmapInfoWindow>
  </GmapMarker>
</template>

<script>
export default {
  props: {
    id: { type: Number, required: true },
    popup: { type: Number, required: false },
    latitude: { type: Number, required: true },
    longitude: { type: Number, required: true },
    name: { type: String, required: true },
    // email: {type: String, required: true},
    phone: { type: String, required: true },
    address: { type: String, required: true },
    address2: { type: String, required: false },
    city: { type: String, required: true },
    zip_code: { type: String, required: true },
    website: { type: String, required: true },
    is_flagship_store: { type: Boolean, required: true },
  },
  computed: {
    // emailLink: (t) => `mailto:${t.email}`,
    phoneLink: (t) => `tel:${t.phone.split(" ").join("")}`,
    websiteLink: (t) =>
      t.website.startsWith("http") ? t.website : `http://${t.website}`,

    position: (t) => ({ lat: t.latitude, lng: t.longitude }),

    location1() {
      return [this.address, this.address2].filter((x) => x).join(", ");
    },
    location2() {
      return [this.city, this.zip_code].filter((x) => x).join(", ");
    },

    icon() {
      const showroom = {
        url: `data:image/svg+xml,${encodeURIComponent(
          require("../assets/icons/showroom.svg")
        )}`,
        scaledSize: { width: 40, height: 40 },
        anchor: new google.maps.Point(20, 20),
      };
      const marker = {
        url: `data:image/svg+xml,${encodeURIComponent(
          require("../assets/icons/marker.svg")
        )}`,
        scaledSize: { width: 23, height: 32 },
      };
      const star = {
        url: `data:image/svg+xml,${encodeURIComponent(
          require("../assets/icons/star.svg")
        )}`,
        scaledSize: { width: 28, height: 28 },
        anchor: new google.maps.Point(14, 14),
      };
      let isShowroom = [1, 235].indexOf(this.id) !== -1;

      if (isShowroom) return showroom;

      return this.is_flagship_store ? star : marker;
    },
  },
  methods: {
    toggle() {
      this.$emit("update:popup", this.popup === this.id ? null : this.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.cl-marker {
  position: absolute;
  transform: translate(-50%, -50%);
}
.popup {
  font-family: $font1;
  font-weight: 400;
  margin-top: 0.5em;

  .name {
    font-size: 1.1em;
    font-weight: 600;
    margin-bottom: 0.5em;
  }

  .info {
    > div {
      margin: 0.5em 0;

      a {
        color: $blue4;
      }
    }

    .location {
      .location1 {
        margin-bottom: 0.2em;
      }
    }
  }
}
</style>
